export class Hotel {
  id: number;
  hotelGroupId: number;
  hotelGroupName: string;
  name: string;
  totalRoom: number;
  logo: any;
  address: string;
  phone: string;
  email: string;
  countryId: number;
  country: string;
  stateId: number;
  state: string;
  cityId: number;
  city: string;
  createdOn: string;
  isActive: boolean;
  corporateName: string;
  hotelCode: string;
  franchiseHotel: string;
  isChecked: boolean;
  name_franchiseHotel: string;

  constructor(value: any = {}) {
    value.name_franchiseHotel = value?.name + ' | ' + value?.franchiseHotel;
    Object.assign(this, value);
  }

  static parseArray(json: any[]): Hotel[] {
    const arr = [];

    if (json) {
      for (const j of json) {
        if (j) {
          arr.push(new Hotel(j));
        }
      }
    }

    return arr;
  }
}
