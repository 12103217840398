


<mat-card class="cardWithShadow">
  <mat-card-content class="p-24">
    <div class="table-responsive">

      <!-- <div class="access-denied-main">
        <h1><code>Access Denied</code></h1>
        <hr style="margin:auto;width:50%">
        <h3>You dont have permission to view this page.</h3>
        <h3>🚫🚫🚫🚫</h3>
      </div> -->

      <div class="blank-layout-container justify-content-center">
        <div class="row">
          <div class="col-12 text-center">
            <img src="/assets/images/backgrounds/404-access-denide.jpg" alt="error-bg" style="width: 40%;" />
            <h2 class="auth-title f-w-600">🚫 Access Denied... We are Sorry!!!</h2>
            <h6 class="f-s-20 f-w-600 m-b-30">The page you're trying to access has restricted access.<br />
              Please refer to your system administrator.</h6>
            <!-- <a mat-flat-button color="primary">Go back to Home</a> -->
          </div>
        </div>
      </div>
      

    </div>
  </mat-card-content>
</mat-card>

