import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {FullMainLoaderComponent} from "./common-components/full-main-loader/full-main-loader.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FullMainLoaderComponent],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Wiser Digits';
}
