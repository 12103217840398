import {Component} from '@angular/core';
import {MenuService} from "../../services/menu.service";
import {MaterialModule} from "../../material.module";

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss'
})
export class AccessDeniedComponent {
}
