<!-- <div [hidden]="!isLoading">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
    <span class="spinner-message">{{loadingMessage}}</span>
  </div>
</div> -->



<div [ngClass]="{'show-loader': isLoading, 'hide-loader': !isLoading}" class="loading-spinner-container">
  <div class="loading-spinner">
    <mat-spinner style="margin-bottom: 20px;"></mat-spinner>
    <span class="spinner-message">{{ loadingMessage }}</span>
  </div>
</div>
